@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;900&display=swap');

*:not(.twitter-tweet) {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html {
  height: 100%;
  background: linear-gradient(-45deg, #ff41a9, #6441A5, #1DA1F2, #007f88);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  text-align: center;
  transition: 0.5s ease;
}

body {
  height: 100%;
  overflow: hidden;
}

@keyframes gradient {
  0% {
      background-position: 30% 50%;
  }
  50% {
      background-position: 70% 50%;
  }
  100% {
      background-position: 30% 50%;
  }
}

.gameOver > .react-reveal {
  animation-fill-mode: backwards !important;
}

#container {
  display: inline-block;
  text-align: center;
  width: 100%;
}

#container * {
  background: none;
}

#version {
  position: fixed;
  top: 8px;
  right: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.25)
}

.Mizkif {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ),  url('./img/gifs/mizkif.gif') center;
    animation: none !important;
    background-size: cover;
}

.HasanAbi {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/hasanabi.gif') center;
  animation: none !important;
    background-size: cover;
}

.Sykkuno {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ),  url('./img/gifs/sykkuno.gif') center;
  animation: none !important;
    background-size: cover;
}

.Ludwig {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ),  url('./img/gifs/ludwig.gif') center;
  animation: none !important;
    background-size: cover;
}

.Pokimane {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/pokimane.gif') center;
  animation: none !important;
    background-size: cover;
}

.xQc {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/xqc.gif') center;
  animation: none !important;
    background-size: cover;
}

.Sodapoppin {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/sodapoppin.gif') center;
  animation: none !important;
    background-size: cover;
}

.MoistCr1TiKaL {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/MoistCr1TiKaL.gif') center;
  animation: none !important;
  background-size: cover;
}

/* OTV */

.Scarra {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/scarra.gif') center;
  animation: none !important;
  background-size: cover;
}

.LilyPichu {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/lilypichu.gif') center;
  animation: none !important;
  background-size: cover;
}

.DisguisedToast {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/disguisedtoast.gif') center;
  animation: none !important;
  background-size: cover;
}

.Yvonnie {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/yvonnie.gif') center;
  animation: none !important;
  background-size: cover;
}

.Michael {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/michaelreeves.gif') center;
  animation: none !important;
  background-size: cover;
}

.Sydeon {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/sydeon.gif') center;
  animation: none !important;
  background-size: cover;
}

.Masayoshi {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/itzmasayoshi.gif') center;
  animation: none !important;
  background-size: cover;
}

.QuarterJade {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/quarterjade.gif') center;
  animation: none !important;
  background-size: cover;
}

.Emiru {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/emiru.gif') center;
  animation: none !important;
  background-size: cover;
}

.Jschlatt {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/jschlatt.gif') center;
  animation: none !important;
  background-size: cover;
}

.Asmongold {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/asmongold.gif') center;
  animation: none !important;
  background-size: cover;
}

.Esfand {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/esfand.gif') center;
  animation: none !important;
  background-size: cover;
}

.Bruce {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/bruce.gif') center;
  animation: none !important;
  background-size: cover;
}

.Tectone {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/tectone.gif') center;
  animation: none !important;
  background-size: cover;
}

.Cyr {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/cyr.gif') center;
  animation: none !important;
  background-size: cover;
}

.Rich {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/rich.gif') center;
  animation: none !important;
  background-size: cover;
}

.Tips {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/tips.gif') center;
  animation: none !important;
  background-size: cover;
}

.Nmplol {
  background: linear-gradient(
    rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)
  ), url('./img/gifs/nmplol.gif') center;
  animation: none !important;
  background-size: cover;
}

h1, h2, h3 {
  color: white;
}

h3 {
  font-weight: 500;
  font-size: 16pt;
}

#logo {
  margin-top: 6vh;
  width: 500px;
  height: auto;
}

#mini-logo {
  margin-top: 2vh;
  width: 100px;
  height: auto;
  cursor: pointer;
}

#landing {
  display: inline-block;
  width: 440px;
}

#select { width: 340px; text-align: center; margin: 0 auto; }
#select #selection-container { margin: 60px 0 24px 0; } 
#select img { width: 100px; height: auto; position: relative; margin: 0 32px; filter: drop-shadow(2px 2px 1px rgba(0,0,0,0.5)); cursor: pointer; opacity: 0.4; transition: 0.25s ease; }
#select img:hover { opacity: 0.7; }
#select img.selected { opacity: 1; }
#select .streamlink { position: relative; }
#select .streamlink .streamtext { left: 50%; bottom: 104%; transform: translateX(-50%); margin: 0; }
#select .streamtext.selected { visibility: visible; }


#game { margin-top: -8vh; }

#gameOver {
  margin: 8vh auto 0 auto;
  display: block;
  width: 440px;
}

.cta-primary {
  display: inline-block;
  width: 160px;
  height: 40px;
  line-height: 40px;
  padding: 4px 0px;
  border: white solid 2px;
  color: white;
  border-radius: 80px;
  cursor: pointer;
  transition: 0.25s ease;
  mix-blend-mode: normal;
  font-size: 16pt;
  font-weight: 700;
  margin-top: 24px;
}

.cta-primary:hover {
  background-color: white !important;
  color: #1DA1F2;
}

#game-prompt {
  margin-top: 12vh;
}

.tweet-card {
  line-height: normal;
  width: 90%;
  max-width: 600px;
  background-color: white !important;
  border-radius: 10px;
  border: rgb(207, 217, 222) solid 2px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  visibility: visible;
  opacity: 1;
  transition: 0.5s ease;
}

.tweet-fake h2 {
  position: relative;
  color: black;
  font-size: 14pt;
  line-height: 14pt;
  font-weight: 700;
  top: 10px;
}

.tweet-fake h3 {
  color: gray;
  font-size: 11pt;
  font-weight: 500;
}

.tweet-fake-icon {
  position: relative;
  top: 12px;
  left: 0px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid white;
}

.icon-placeholder {
  background-color: rgb(207, 217, 222) !important;
}

.tweet-container {
  display: block;
  text-align: left;
  margin: 0 24px;
}

.pfp-hidden, .handle-hidden {
  background-color: rgb(207, 217, 222) !important;
}

.pfp-hidden {
  display: inline-block;
  height: 44px;
  width: 44px;
  margin-top: 18px;
  border-radius: 50%;
}

.handle-container {
  display: inline-block;
  margin: 0 0 4px 18px;
}

.handle-hidden {
  display: block;
  height: 14px;
  width: 200px;
}

.handle-hidden:last-of-type {
  margin-top: 8px;
}

.tweet-text {
  font-size: 16pt;
}

.tweet-datetime {
  color: rgb(83, 100, 113);
}

.mc-container {
  width: 100%;
  max-width: 680px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.mc-row {
  display: inline-block;
}

.choice {
  display: inline-block;
  width: 280px;
  height: 40px;
  line-height: 40px;
  padding: 4px 0px;
  border-radius: 80px;
  cursor: pointer;
  transition: 0.25s ease;
  mix-blend-mode: normal;
  font-size: 16pt;
  font-weight: 700;
  margin: 12px 24px;
}

.choice-default {
  color: #1DA1F2;
  background-color: white !important;
}

.choice-default:hover {
  background-color: #1DA1F2 !important;
  color: white;
}

.choice-default:active {
  background-color: #128ad4 !important;
}

.choice-wrong {
  background-color: #c40000 !important;
  color: white;
}

.choice-correct {
  background-color: #00a743 !important;
  color: white;
}

.disabled {
  pointer-events: none;
}

#high-score, #score, #stats-prompt {
  position: fixed;
  color: white;
  font-size: 16pt;
}

#score {
  font-size: 24pt;
  font-weight: 900;
  bottom: 40px;
  right: 60px;
}

#high-score {
  top: 16px;
  left: 24px;
  font-weight: 700;
  font-size: 16pt;
}

#stats-prompt {
  top: 48px;
  left: 24px;
  font-weight: 700;
  font-size: 16pt;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
}

#stats-prompt:hover {
  color: #1DA1F2;
}

#stats-container {
  position: fixed;
  text-align: left;
  margin: 24px 0 0 24px;
  color: white;
  font-weight: 700;
  font-size: 16pt;
  line-height: 22pt;
  background-color: rgba(0,0,0,0.9);
  padding: 12px;
}

#stats-container h2 {
  margin-top: 0;
  margin-bottom: 12px;
  text-decoration: underline;
}

.stats-left {
  display: inline-block;
  text-align: left;
}

.stats-right {
  display: inline-block;
  text-align: right;
}

#score-mobile {
  display: none;
}

#tweet-placeholder {
  overflow: hidden;
  height: 320px;
  line-height: 320px;
  position: relative;
}

.hide-visibility {
  visibility: hidden;
  opacity: 0;
}

.hide-display {
  display: none;
}

.twitter-tweet-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.show-tweet {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
}

#gameOverScore {
  -webkit-text-stroke: 2px white;
  text-stroke: 2px white;
  color: rgba(0,0,0,0);
  font-size: 60pt;
  font-weight: 900;
}

#next-arrow {
  position: absolute;
  fill: white;
  top: 40px;
  right: -80px;
  border-radius: 16px;
  transition: 0.25s ease;
  z-index: 1;
}

#next-arrow:hover {
  cursor: pointer;
  fill: #acdfff;
}

.emotes-wrapper {
  position: static;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.emote {
  height: 80px;
  width: 80px;
  filter: drop-shadow(2px 2px 1px rgba(0,0,0,0.5));
}

.tooltip {
  position: absolute;
}

.streamlink {
  display: inline-block;
}

.tooltip1 { top: 100px; left: calc(50% - 420px); }
.tooltip2 { top: 250px; left: calc(50% - 460px); }
.tooltip3 { top: 400px; left: calc(50% - 420px); }
.tooltip4 { top: 100px; right: calc(50% - 420px); }
.tooltip5 { top: 250px; right: calc(50% - 460px); }
.tooltip6 { top: 400px; right: calc(50% - 420px); }

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white !important;
  color: black;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;

  position: absolute;
  bottom: 100%;
  left: -12px;
  z-index: 1;
  margin-left: -10px;
  margin-bottom: 6px;
}

.streamers-container {
  position: static;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.streamers-containerLeft .streamlink, .streamers-containerRight .streamlink { position: absolute; }

.streamers-containerLeft .streamlink1 { top: 160px; left: calc(50% - 400px); }
.streamers-containerLeft .streamlink2 { top: 260px; left: calc(50% - 300px); }
.streamers-containerLeft .streamlink3 { top: 360px; left: calc(50% - 400px); }
.streamers-containerLeft .streamlink4 { top: 460px; left: calc(50% - 300px); }
.streamers-containerLeft .streamlink9 { top: 560px; left: calc(50% - 400px); }
.streamers-containerLeft .streamlink11 { top: 660px; left: calc(50% - 300px); }

.streamers-containerRight .streamlink5 { top: 160px; right: calc(50% - 400px); }
.streamers-containerRight .streamlink6 { top: 260px; right: calc(50% - 300px); }
.streamers-containerRight .streamlink7 { top: 360px; right: calc(50% - 400px); }
.streamers-containerRight .streamlink8 { top: 460px; right: calc(50% - 300px); }
.streamers-containerRight .streamlink10 { top: 560px; right: calc(50% - 400px); }

.streamlink .streamtext {
  visibility: hidden;
  width: 120px;
  background-color: white !important;
  color: black;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;

  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -60px;
}

.streamtext { bottom: 100%; }

a[href="https://twitter.com/michaelreeves"] .streamtext,
a[href="https://twitter.com/disguisedtoast"] .streamtext,
#selection-container .selectLSF { width: 140px !important; transform: translateX(-10px) }

.tooltip1:hover .tooltiptext1,
.tooltip2:hover .tooltiptext2,
.tooltip3:hover .tooltiptext3,
.tooltip4:hover .tooltiptext4,
.tooltip5:hover .tooltiptext5,
.tooltip6:hover .tooltiptext6,
.streamlink1:hover .streamtext1,
.streamlink2:hover .streamtext2,
.streamlink3:hover .streamtext3,
.streamlink4:hover .streamtext4,
.streamlink5:hover .streamtext5,
.streamlink6:hover .streamtext6,
.streamlink7:hover .streamtext7,
.streamlink8:hover .streamtext8,
.streamlink9:hover .streamtext9,
.streamlink10:hover .streamtext10,
.streamlink11:hover .streamtext11 {
  visibility: visible;
}

.tooltiptext:before,
.streamtext:before {
  content: '';
  height: 0;
  position: absolute;
  width: 0;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-top-color: white;
  z-index: 999;
}

#selection-container .tooltip1 { top: 200px; left: 20px; }

.streamer {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid white;
  margin: 0;
  transition: 0.35s ease;
  filter: drop-shadow(0px 8px 8px rgba(0,0,0,0.25));
  z-index: 1;
}

.streamer:hover {
  filter: drop-shadow(0px 2px 3px rgba(0,0,0,0.5));
}

.streamer:hover {
  transform: translateY(8px);
}

.developer {
  font-size: 14pt;
  margin-top: 240px;
}

.dev-link {
  font-weight: 700;
  color: white;
}

.dev-link:hover {
  color: #1DA1F2;
}

#alert-container {
  position: fixed;
  bottom:  40px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

#alert-container svg {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

#alert-reload {
  display: inline-block;
  background-color: white !important;
  border: 2px solid red;
  border-radius: 10px;
  color: red;
  padding: 12px 28px;
  font-weight: 500;
  filter: drop-shadow(0px 8px 8px rgba(0,0,0,0.25));
}

.twitter-btn {
  background-color: #1DA1F2 !important;
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 700;
}

.twitter-btn:active {
  background-color: #128ad4 !important;
}

.twitter-logo {
  height: 16px;
  width: auto;
  transform: translateY(2px);
  padding-right: 6px;
}

.footer {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  line-height: 6pt;
  z-index: 0;
}

.footer span { font-weight: 700; }

.footer h2 { font-weight: 500; font-size: 10pt; }

@media (max-width: 1200px) {
  .emote {
    height: 60px;
    width: 60px;
  }

  .tooltip .tooltiptext {
    margin-left: -28px;
  }

  .tooltip1, .tooltip2, .tooltip3 {
    left: 60px;
  }

  .tooltip4, .tooltip5, .tooltip6 {
    right: 120px;
  }
  
  .tooltip1 { top: 100px; }
  .tooltip2 { top: 250px; }
  .tooltip3 { top: 400px; }
  .tooltip4 { top: 100px; }
  .tooltip5 { top: 250px; }
  .tooltip6 { top: 400px; }

  #next-arrow {
    position: static;
    display: inline-block;
    margin-top: 20px;
  }
}

@media (max-width: 900px) {
  .streamers-container {
    width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 900px) and (orientation:portrait) {
  .choice {
    display: block;
    width: 400px;
    height: 60px;
    line-height: 60px;
    border-radius: 80px;
    font-size: 32px;
    margin: 12px 0;
  }

  .choice:not(:last-child) {
    margin-bottom: 24px;
  }

  #next-arrow {
    height: 80px;
    width: 80px;
    display: block;
    margin: 40px auto 0 auto;
  }

  .tooltip1, .tooltip2, .tooltip3 {
    left: 60px;
  }

  .tooltip4, .tooltip5, .tooltip6 {
    right: 140px;
  }
  
  .tooltip1 { top: 550px; }
  .tooltip2 { top: 675px; }
  .tooltip3 { top: 800px; }
  .tooltip4 { top: 550px; }
  .tooltip5 { top: 675px; }
  .tooltip6 { top: 800px; }

}

@media (max-width: 600px) {
  body {
    position: absolute;
    width: 100vw;
  }

  #logo {
    width: 80%;
  }

  #landing, #game {
    width: 90%;
    margin: 0 auto;
  }

  #gameOver {
    width: 90%;
  }

  .tweet-text, .tweet-datetime {
    font-size: 12pt;
  }
  
  .handle-hidden {
    width: 120px;
  }

  .mc-container {
    margin-top: 20px;
  }

  .mc-row {
    display: block;
  }

  .streamers-container {
    width: 320px;
  }

  .streamer {
    height: 64px;
    width: 64px;
    margin: 4px;
  }
  
  .streamlink2 .streamtext, .streamlink4 .streamtext, .streamlink6 .streamtext, .streamlink8 .streamtext { bottom: 100%; }

  .choice {
    display: inline-block;
    width: 40%;
    height: 24px;
    line-height: 24px;
    border-radius: 80px;
    font-size: 14px;
    margin: 4px 12px;
  }

  .choice:not(:last-child) {
    margin-bottom: 12px;
  }

  #next-arrow {
    height: 40px;
    width: 40px;
    margin: 8px auto 0 auto;
  }

  .emotes-wrapper {
    display: none;
  }

  #game-prompt {
    font-size: 10pt;
    margin: 12vh 0 12px 0;
  }

  #score {
    display: none;
  }
  
  #score-mobile {
    display: block;
    position: fixed;
    top: 28px;
    left: 16px;
    color: white;
    font-size: 16pt;
    font-weight: 700;
  }

  #high-score {
    left: 16px;
    font-size: 8pt;
    font-weight: 500;
  }

  .developer {
    margin-top: 80px;
    font-size: 12pt;
  }
}